.heading1 {
  flex: 0 1;
  font-size: 5rem;
  line-height: 1.1;
  color: #ffffff;
  margin-top: 5rem;
  margin-left: 5rem;
}

.heading2 {
  flex: 0 1;
  font-size: 3rem;
  line-height: 1.1;
  color: #ffffff;
  margin-top: 1rem;
  margin-left: 5rem;
}

.middle {
  flex: 1;
  margin-left: 5rem;
}

.footer {
  flex: 0 1;
  font-size: 1.5rem;
  line-height: 1.1;
  color: #ffffff;
  margin-left: 5rem;
  margin-bottom: 4rem;
}

.email_link:link,
.email_link:visited {
  font-size: 2.5rem;
  color: #ffffff;
  text-decoration: none;
  transition: all, 0.5s;
}

.email_link:hover,
.email_link:active {
  color: #00ffff;
}
